#the-footer {
  background-color: #ffffff;
  border-top: 2px solid #40a9ff;
  text-align: left;
  position: fixed;
  bottom: 0;
  padding: 5px;
  padding-top: 0;
  margin-bottom: -20px;
  margin-left: 20px;
  width: 100%;
  z-index: 2;
}
