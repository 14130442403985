#the-header {
  background: #fff;
  padding: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
}

#main-title {
  /* font-weight: bold; */
  font-size: large;
  color: #002766;
}

.logo {
  margin-top: 7px;
  margin-left: 10px;
  float: left;
}

.top-right-avatar {
  float: right;
  margin-right: 20px;
  margin-top: 15px;
}
