.site-page-header-ghost-wrapper {
  background-color: white;
  width: 100%;
  position: fixed;
  z-index: 2;
  border-top: 4px solid #096dd9;
  border-bottom: 3px solid #d9d9d9;
  padding-left: 20px;
  margin-top: -2px;
}
