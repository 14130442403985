.whole-container {
  margin: auto;
  width: 100%;
  max-width: 550px;
  padding-right: 20px;
  padding-left: 20px;
}

.logo-container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30vh;
  max-width: 300px;
  padding-bottom: 50px;
}
