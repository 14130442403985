.site-layout-background {
  background: #fff;
}

#content-container {
  margin-left: 20px;
}

#header-content-container {
  margin-left: 200px;
  margin-bottom: 60px;
}
